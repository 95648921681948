import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { absoluteCenter } from "./styles";
import TouchAppIcon from "@mui/icons-material/TouchApp";

interface Props {
  onClick: () => void;
}
export const Home = (props: Props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      onClick={props.onClick}
    >
      <Wrapper>
        <RelativeWrapper>
          <img
            style={{
              top: "8%",
              width: "80%",
              ...absoluteCenter,
            }}
            src="/images/logo.png"
            alt="logo"
          />
          <img
            style={{
              top: "18%",
              width: "65%",
              ...absoluteCenter,
            }}
            src="/images/RunTicketIcon.png"
            alt="logo"
          />
          <div
            style={{
              ...absoluteCenter,
              bottom: "4%",
              fontSize: "20px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <p>画面をタッチしてください</p>
            <BlinkingIcon>
              <TouchAppIcon style={{ fontSize: "50px" }} />
            </BlinkingIcon>
          </div>
        </RelativeWrapper>
      </Wrapper>
    </div>
  );
};
const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const BlinkingIcon = styled.div`
  animation: ${blinkAnimation} 3s infinite;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  border-style: solid;
  border-color: #006c9b;
  border-radius: 20px;
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
