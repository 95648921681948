import styled from "@emotion/styled";
import { LoadingAnimation } from "./components/LoadingAnimation";

export const Fetch = () => {
  return (
    <div>
      <TextWrapper className="japanese_L">注文情報を取得しています</TextWrapper>
      <LoadingAnimation type={"NewtonsCradle"} top="42%" size={120} />
    </div>
  );
};

const TextWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 35px;
  color: #006c9b;
`;
