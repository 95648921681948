import styled from "@emotion/styled";
import {
  LeapFrog,
  Orbit,
  Ping,
  JellyTriangle,
  NewtonsCradle,
} from "@uiball/loaders";

interface LoadingAnimationProps {
  type: "leapfrog" | "orbit" | "ping" | "JellyTriangle" | "NewtonsCradle";
  top?: string;
  bottom?: string;
  size?: number;
  speed?: number;
}
export const LoadingAnimation = (props: LoadingAnimationProps) => {
  return (
    <>
      {props.type === "leapfrog" && (
        <div
          style={{
            position: "absolute",
            top: props.top,
            bottom: props.bottom,
            right: "50%",
            transform: "translate(50%,50%)",
          }}
        >
          <LeapFrog
            size={props.size ?? 100}
            speed={props.speed ?? 1.8}
            color="#006C9B"
          />
        </div>
      )}
      {props.type === "ping" && (
        <Wrapper top={props.top}>
          <Ping
            size={props.size ?? 170}
            speed={props.speed ?? 1.5}
            color="#006C9B"
          />
        </Wrapper>
      )}
      {props.type === "orbit" && (
        <Wrapper top={props.top}>
          <Orbit
            size={props.size ?? 60}
            speed={props.speed ?? 1.2}
            color="#006C9B"
          />
        </Wrapper>
      )}
      {props.type === "JellyTriangle" && (
        <Wrapper top={props.top}>
          <JellyTriangle
            size={props.size ?? 60}
            speed={props.speed ?? 1.2}
            color="#006C9B"
          />
        </Wrapper>
      )}
      {props.type === "NewtonsCradle" && (
        <Wrapper top={props.top}>
          <NewtonsCradle
            size={props.size ?? 60}
            speed={props.speed ?? 1.2}
            color="#006C9B"
          />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div<{ top?: string }>`
  position: fixed;
  top: ${(props) => props.top};
  right: 50%;
  transform: translate(50%, 50%);
`;
