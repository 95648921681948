import React, { useState, useEffect, useRef } from "react";
import jsqr, { QRCode } from "jsqr";
import "../view/styles/App.css";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { ErrorMessages } from "../api/type";
import { Timer } from "../api/helper";
import { ThemeButton } from "./components/ThemeButton";
import { absoluteCenter } from "./styles";
export type { QRCode } from "jsqr";

export type QRReaderProps = {
  width: number;
  height?: number;
  pause?: boolean;
  showQRFrame?: boolean;
  timerInterval?: number;
  cameraType: "user" | "environment";
  recognizeCallback?: (e: QRCode) => void;
  err?: ErrorMessages | null;
};

type Point = {
  x: number;
  y: number;
};

type OverlayPosition = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const RelativeWrapperDiv = styled.div<QRReaderProps>`
  position: relative;
  margin: auto;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const VideoArea = styled.video`
  position: absolute;
  z-index: -100;
`;

const OverlayDiv = styled.div<OverlayPosition>`
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const QRReader: React.FC<QRReaderProps> = (props) => {
  const [overlay, setOverlay] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const video = useRef(null as any);
  const timerId = useRef(null);

  const drawRect = (topLeft: Point, bottomRight: Point) => {
    setOverlay({
      top: topLeft.y < bottomRight.y ? topLeft.y : bottomRight.y,
      left: topLeft.x < bottomRight.x ? topLeft.x : bottomRight.x,
      width: Math.abs(bottomRight.x - topLeft.x),
      height: Math.abs(bottomRight.y - topLeft.y),
    });
  };

  useEffect(() => {
    (async () => {
      if (props.pause) {
        video.current.pause();
        timerId.current = null;
        return;
      }

      const { width, height } = props;

      const constraints = {
        audio: false,
        video: {
          facingMode: props.cameraType,
          width,
          height,
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      video.current.srcObject = stream;
      video.current.play();

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = props.width;
      canvas.height = props.width;

      const tick = () => {
        if (!timerId.current) {
          context?.drawImage(video.current, 0, 0, props.width, props.width);
          const imageData = context?.getImageData(
            0,
            0,
            props.width,
            props.width
          );
          if (imageData) {
            const qr = jsqr(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: "invertFirst",
            });

            if (qr) {
              if (props.showQRFrame) {
                drawRect(
                  qr.location.topLeftCorner,
                  qr.location.bottomRightCorner
                );
              }
              if (props.recognizeCallback) props.recognizeCallback(qr);
            }
          }
          context?.clearRect(0, 0, props.width, props.width);
          requestAnimationFrame(tick);
        }
      };
      requestAnimationFrame(tick);
    })();
  }, [props]);
  useEffect(() => {
    video.current.pause();
  }, [props.pause]);

  useEffect(() => {
    (async () => {
      await Timer(60000);
      window.location.reload();
    })();
  }, []);
  return (
    <div>
      <QRWrapper>
        {!props.err && (
          <Description textColor={"#ffffff"} className="japanese_L">
            枠内に合わせてください
          </Description>
        )}
        {props.err && (
          <Description textColor={"#ff0000"} className="japanese_L">
            {props.err}
          </Description>
        )}
        <div
          style={{
            transform: "scale(-1, 1)",
          }}
        >
          <img style={QRGuide} src="/images/qr_guide.png" alt="qr" />
          <DescriptionWrapper />
          <FadeIn>
            <img style={QRIcon} src="/images/qrcode-scan.svg" alt="qr" />
          </FadeIn>
          <canvas id="canvas" hidden></canvas>
          {!props.pause && <div id="box"></div>}
          <RelativeWrapperDiv {...props}>
            <VideoArea
              ref={video}
              style={{
                borderStyle: "none",
                borderRadius: "10px",
              }}
            ></VideoArea>
            <OverlayDiv {...overlay}></OverlayDiv>
          </RelativeWrapperDiv>
        </div>
      </QRWrapper>
      <ThemeButton
        style={{
          ...absoluteCenter,
          width: "500px",
          height: "70px",
          bottom: "5%",
        }}
        text={"戻る"}
        onClick={function (): void {
          window.location.href = "/";
        }}
      />
    </div>
  );
};

// propsのデフォルト値を設定
QRReader.defaultProps = {
  width: 300,
  height: 300,
  pause: false,
  showQRFrame: true,
  timerInterval: 1,
};

const QRWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const QRGuide: React.CSSProperties = {
  width: "500px",
  position: "absolute",
  top: 0,
  left: 0,
};

const QRIcon: React.CSSProperties = {
  width: "230px",
  position: "absolute",
  bottom: "57%",
  left: "50%",
  transform: "translate(-50%, 50%) scale(-1, -1)",
};

const fadeIn = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
`;
const FadeIn = styled.div`
  animation-iteration-count: infinite;
  animation: ${fadeIn} 3s infinite;
`;

const DescriptionWrapper = styled.div`
  width: 500px;
  height: 62px;
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 0 0 10px 10px;
  transform: translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0.5);
`;

const Description = styled.div<{ textColor: string }>`
  font-size: 30px;
  color: ${(props) => props.textColor};
  text-align: center;
  position: absolute;
  width: 500px;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
`;
export default QRReader;
