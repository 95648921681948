import styled from "@emotion/styled";
import CachedIcon from "@mui/icons-material/Cached";
import { Fab } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <Wrapper>
      <Fab
        style={{ ...CircleButtonWrapper, left: "20px" }}
        onClick={() => {
          window.location.reload();
        }}
      >
        <CachedIcon fontSize="large" />
      </Fab>
      <Fab
        style={{ ...CircleButtonWrapper, right: "20px" }}
        component={Link}
        to={"/setting"}
      >
        <SettingsIcon fontSize="large" />
      </Fab>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 80px;
  width: 100vw;
`;

const CircleButtonWrapper: React.CSSProperties = {
  position: "fixed",
  width: "70px",
  height: "70px",
  borderStyle: "solid",
  color: "#006c9b",
  borderColor: "#006c9b",
  boxShadow: "none",
  top: "20px",
};
