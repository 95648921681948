import { Timestamp } from "firebase/firestore";

export enum Mode {
  home = "home",
  reader = "reader",
  fetch = "fetch",
  print = "print",
  complete = "complete",
  admin = "admin",
  notFound = "notFound",
  ticketingFailed = "ticketingFailed",
}

export enum ErrorMessages {
  notType = "無効な形式です",
  notFound = "見つかりませんでした",
}
export interface baseProps {
  setMode: (st: Mode) => void;
}

export type paymentType = "" | "paypay" | "stripe";

export interface MenuData {
  title: string;
  description: string;
  price: number;
  id: string;
  image: string;
  category: string;
  isBigSize: boolean;
  bigSizeDiffPrice: number;
  isStatus: boolean;
  quantity: number;
}

export interface UserData {
  uid: string;
  studentName: string;
  mailAddress: string;
}

export interface OrderData {
  id: string;
  user: UserData;
  totalPrice: number;
  menu: MenuData[];
  isStatus: "not_payed" | "ordered" | "cooked" | "complete";
  date: Timestamp;
  payment: paymentType;
  orderNumber?: number;
  checkoutId?: string;
}

export interface OrderListProps {
  title: string;
  count: number;
}

export type subscriberStatus = "scanned" | "failed" | "success";
export interface TicketSubscribeTypes {
  id: string;
  title: string;
  date: string;
  time: string;
  status: subscriberStatus;
}

export const adminId =
  "7AJ1VeJt@wkncH8qG?aM2vJRw5YEbO&9RjP_hhyGVb!glXPcUecWMCqjWVpb=mQ5eRvivSnmvalk+ZU7$qg%uo7Ku6c?2Txy49O?1d%k9ND-Ur27eJ0#+fNuSA3tJCbkrp.Qc6o9S$KHGaoi%#j?SW5F=XVH+N9btX7QyX3&FJYjCiag7_YRSvpTvDi%3TFScOc1*%";
